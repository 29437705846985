#alert {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000090;
	padding: 15px;
	z-index: 200;
}

#alert .content {
	background-color: #fff;
	padding: 15px;
	width: 100%;
	max-width: 500px;
	border-radius: 5px;
}

#alert .title {
	text-align: center;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 15px;
}

#alert .message {
	margin-bottom: 15px;
	text-align: center;
}

#alert .buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 -10px;
}

#alert .button {
	border: 0;
	display: block;
	flex-grow: 1;
	margin: 0 10px;
	padding: 10px;
	font-weight: bold;
	color: inherit;
	border-radius: 5px;
}

#alert .button.success {
	background-color: rgb(34, 168, 0);
	color: #fff;
}
