@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
	box-sizing: border-box;
}

:root {
	--c1: #213740;
	--c1c: #ffffff;
	--c2: #bf0426;
	--c2c: #ffffff;
	--c3: #f0f0f0;
	--c3c: #000;
	--c4: #bf0426;
	--c4c: #fff;
	--c5: #213740;
	--c5c: #ffffff;
	--c6: #eeeeee;
	--c6c: #000000;
	--c7: #eeeeee;
	--c7c: #000000;
}

body {
	font-family: "Inter", sans-serif !important;
	margin: 0;
}

button {
	font-family: "Inter", sans-serif !important;
}

.bg-success {
	background-color: #8fd19e !important;
}

.bg-warning {
	background-color: #ffdf7e !important;
}

.text-right {
	text-align: right;
}

html {
	height: 100dvh;
}

body {
	height: 100dvh;
}

#root {
	height: 100dvh;
}

.main {
	display: flex;
	flex-direction: column;
	height: 100dvh;
	padding: 10px;
	background-color: var(--c1);
	gap: 10px;
}

.main header {
	background-color: var(--c5);
	color: var(--c5c);
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
}

.main header > div {
	display: flex;
	gap: 5px;
	align-items: center;
}

.main header .header-right button {
	text-transform: uppercase;
	font-weight: 700;
}

.main .content {
	flex-grow: 1;
	display: flex;
	gap: 5px;
	flex-direction: column;
	overflow: hidden;
}

.main .content .scroll {
	flex-grow: 1;
	overflow: auto;
	background-color: var(--c1c);
	padding: 5px;
	border-radius: 5px;
}

.main footer {
	display: grid;
	gap: 5px;
	flex-shrink: 0;
	grid-template-columns: 1fr 1fr 1fr;
}

.main footer .top {
	background-color: var(--c5);
	color: var(--c5c);
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 5px;
	flex-shrink: 0;
}

.section-title {
	padding: 10px;
}

.list-button {
	border: 1px solid var(--c1);
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 5px;
	color: inherit;
	gap: 5px;
	transition: all 0.2s linear;
}

.list-button.vertical {
	display: block;
	padding: 0px;
	margin-bottom: 5px;
}

.list-button.success {
	background-color: rgb(172, 255, 172);
}

.list-button .product-name {
	display: block;
	margin: 0;
	background-color: transparent;
	border: 0;
	flex-grow: 1;
	text-align: left;
	font-size: 12px;
	font-weight: 700;
	padding: 0px;
	color: inherit;
}

button.list-button {
	cursor: pointer;
	text-align: left;
	background-color: var(--c1c);
	text-transform: uppercase;
	font-weight: 700;
	padding: 10px 10px;
}

button.list-button:hover {
	background-color: #eee;
}

button.list-button.selected {
	background-color: var(--c1);
	color: var(--c1c);
}

.list-table button.list-button {
	text-transform: none;
	font-weight: 300;
	width: 100%;
}

.list-lists button.list-button {
	width: 100%;
}

.list-title {
	border: 0;
	padding: 0 10px;
	min-height: 40px;
	display: flex;
	align-items: center;
	width: 100%;
	color: var(--c7c);
	background-color: var(--c7);
	font-size: 12px;
	margin-bottom: 3px;
	border-radius: 5px;
	text-transform: uppercase;
	font-weight: 700;
}

.list-title.selected {
	color: var(--c4c);
	background-color: var(--c4);
}

.btn {
	background-color: white;
	border: 1px solid #ccc;
	padding: 0 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	color: inherit;
	position: relative;
	border-radius: 5px;
}

.btn.btn-block {
	display: flex;
	width: 100%;
	border-radius: 5px;
}

.btn.btn-square {
	width: 40px;
	font-size: 30px;
	padding: 5px;
	line-height: 0;
}

.btn.btn-icon {
	padding: 5px;
}

.btn.btn-icon svg {
	width: 25px;
	height: auto;
}

.btn.btn-circle {
	width: 25px;
	min-height: 25px;
	border-radius: 100% !important;
}

.btn.btn-icon {
	display: flex;
	gap: 5px;
	padding: 5px;
}

.btn.btn-circle.sm {
	width: 25px;
	min-height: 25px;
}

.btn.btn-primary {
	background-color: var(--c1);
	color: var(--c1c);
	border: 1px solid var(--c1);
	text-transform: uppercase;
	font-weight: 700;
}

.btn.btn-primary i {
	color: var(--c1c);
}

.btn.btn-secondary {
	background-color: var(--c2);
	color: var(--c2c);
	border: 1px solid var(--c2);
	border-radius: 5px;
	text-transform: uppercase;
	font-weight: 700;
}

.btn.btn-tertiary {
	background-color: var(--c3);
	color: var(--c3c);
	border: 1px solid var(--c3);
	border-radius: 5px;
	text-transform: uppercase;
	font-weight: 700;
}

.btn .badge {
	border-radius: 5px;
	background-color: #fff;
	color: #000;
	font-weight: bold;
	padding: 2px;
	min-width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-products {
	overflow: auto;
	flex-grow: 1;
	background-color: var(--c1c);
	padding: 5px;
	border-radius: 5px;
	margin-bottom: 5px;
}

.cart-buttons {
	display: flex;
	flex-shrink: 0;
	background-color: var(--c1c);
	padding: 10px;
	border-radius: 5px;
	gap: 5px;
}
.cart-buttons button {
	text-transform: uppercase;
	font-weight: 700;
}

.plus_minus_container {
	display: flex;
	align-items: center;
	gap: 5px;
}

.plus_minus_container > div {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
}
.text-box {
	padding: 5px;
	text-transform: uppercase;
	font-weight: 700;
}
.text-box .small {
	text-transform: none;
}

.info-box {
	background-color: var(--c4);
	color: var(--c4c);
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	margin-bottom: 5px;
}

.color-box {
	background-color: var(--c3);
	color: var(--c6c);
	margin-bottom: 5px;
	padding: 5px;
	border-radius: 5px;
	min-height: 40px;
	display: flex;
	flex-direction: column;
	gap: 3px;
	justify-content: center;
}

.color-box .title {
	font-weight: bold;
	font-size: 14px;
}

.alert-box {
	background-color: var(--c2);
	color: white;
	padding: 5px;
	margin-bottom: 5px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 3px;
	justify-content: center;
}

.alert-box .title {
	font-weight: bold;
}
.popup .content.list-variants {
	font-size: 12px;
}
.popup .content.list-variants .buttons-group div {
	width: 10px;
	text-align: center;
}

.img-box {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;
}

.img-box img {
	width: 100%;
	max-width: 200px;
	max-height: 200px;
}

.small {
	font-size: 10px;
	font-weight: 400;
}

.extrasmall {
	font-size: 12px;
}

.buttons-group {
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: 700;
	font-size: 14px;
}

.buttons-group.full {
	justify-content: space-between;
}

.buttons-group.right {
	justify-content: flex-end;
}

.buttons-group.box {
	background-color: var(--c3);
	padding: 3px 5px;
}

.allergens {
	display: flex;
	gap: 5px 10px;
	flex-wrap: wrap;
	margin-top: 10px;
}
.allergens > div {
	display: flex;
	gap: 5px;
	align-items: center;
	font-size: 10px;
}

.allergens img {
	width: 15px;
}
.product-sheet .color-box {
	padding: 10px;
}
.product-sheet .list-ingredients .small {
	display: flex;
	gap: 5px 10px;
	flex-wrap: wrap;
	margin-top: 5px;
}
.product-sheet + .buttons-group {
	padding: 10px;
}

.toast {
	position: absolute;
	width: 100%;
	bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.toast .content {
	background-color: #333;
	color: #fff;
	padding: 5px;
	border-radius: 10px;
	font-size: 12px;
	width: 100%;
	max-width: 250px;
	text-align: center;
}

.fade {
	opacity: 1;
	color: #000;
	transition: all 0.2s linear 0.1s;
	pointer-events: none;
}

.fade.hide {
	opacity: 0;
	color: red;
}

.full-centered {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.separator-vertical {
	width: 1px;
	height: 15px;
	background-color: #fff;
}

.header-src {
	position: relative;
}

.header-src input {
	height: 40px;
	width: 100px;
}

.icon::before {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
}

.header-src svg {
	position: absolute;
	top: 7px;
	right: 5px;
	width: 25px;
	pointer-events: none;
	color: var(--c2);
}

.plus-minus {
	display: flex;
	justify-content: center;
	gap: 5px;
	padding: 10px;
}

.plus-minus button {
	border: 0;
	background-color: var(--c3);
	color: var(--c3c);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	flex-shrink: 0;
	border-radius: 100%;
	font-size: 30px;
	font-weight: bold;
	line-height: 0;
}

.plus-minus input {
	text-align: center;
	font-size: 20px;
	border: 0px;
	width: 100%;
	max-width: 200px;
}

.covers-table > div {
	padding: 10px;
}

/* SHOPS LISTS*/
.shops-list .list-button {
	width: 100%;
}

.group {
	display: flex;
	gap: 5px;
}

.group.vertical {
	flex-direction: column;
}

.group.vcenter {
	align-items: center;
}

.group.full {
	justify-content: space-between;
}

.group.title {
	font-weight: bold;
	font-size: 12px;
	background-color: var(--c1);
	color: var(--c1c);
	padding: 5px;
}

.lists-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 5px;
}

.tables-container {
	display: grid;
	gap: 5px;
}

.tables-container button {
	padding: 5px;
	border: 1px solid var(--c1);
	color: var(--c1);
	background-color: var(--c1c);
	border-radius: 5px;
	text-align: left;
}

.tables-container button .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tables-container button .info {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.tables-container button .header > div:nth-child(2) {
	text-align: right;
}

.tables-container button .duration {
	background-color: var(--c2);
	color: var(--c2c);
	padding: 2px 5px;
	font-size: 12px;
	border-radius: 5px;
}

@media (width >= 600px) {
	.tables-container {
		grid-template-columns: 1fr 1fr;
	}

	.tables-container button {
		font-size: 16px;
	}
}

@media (width >= 800px) {
	.tables-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media (width >= 1200px) {
	.tables-container {
		grid-template-columns: repeat(4, 1fr);
	}
}

.product-line {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.qr-code-container {
	text-align: center;
}

.qr-code-container img {
	width: 100%;
}

.qr-code-container .pin {
	font-weight: bold;
}

.qr-code-container .pin > div:nth-child(2) {
	font-size: 50px;
}

.text-large {
	font-size: 20px;
}

.text-bold {
	font-weight: bold;
}

.ingredients-list {
	display: flex;
	gap: 5px;
	justify-content: space-between;
	flex-wrap: wrap;
}

.ingredients-list > div {
	display: flex;
	gap: 5px;
	align-items: center;
	border: 1px solid var(--c1);
	border-radius: 5px;
	padding: 5px;
	width: fit-content;
	flex-grow: 1;
}

.ingredients-list > div input {
	width: 30px;
	height: 30px;
}

.category-title {
	font-weight: bold;
	background-color: var(--c1);
	color: var(--c1c);
	border-radius: 5px;
	padding-bottom: 5px;
	text-align: center;
}

.expire {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000095;
	display: flex;
	align-items: center;
	justify-content: center;
}

.expire .content {
	background-color: #fff;
	padding: 30px;
	border-radius: 5px;
	border: 10px solid var(--c2);
	max-width: 350px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.true-false-buttons {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 15px;
}

.true-false-buttons > button {
	border: 0;
	background-color: var(--c3);
	color: var(--c3c);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-size: 16px;
	font-weight: bold;
	padding: 10px;
}

.true-false-buttons > button.selected {
	background-color: var(--c1);
	color: var(--c1c);
}

.gt {
	display: grid;
	margin-bottom: 10px;
}

.gt-4 {
	grid-template-columns: auto auto auto auto;
}

.gt > div {
	padding: 5px;
	border-bottom: 1px solid var(--c3);
}
