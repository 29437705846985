.horizzontal-menu {
  overflow: auto;
  border-bottom: 1px solid black;
  flex-shrink: 0;
  flex-grow: 0;
}

.horizzontal-menu .scroll-content {
  padding: 5px;
  display: flex;
  gap: 5px;
  overflow-x: auto;
  border-radius: 5px;
}

.horizzontal-menu button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid var(--c1);
  color: var(--c1);
  background-color: var(--c1c);
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  min-height: 35px;
}

.horizzontal-menu button.active {
  border: 1px solid var(--c2);
  color: var(--c1c);
  background-color: var(--c2);
}

/* width */
.horizzontal-menu .scroll-content::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
.horizzontal-menu .scroll-content::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
.horizzontal-menu .scroll-content::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
.horizzontal-menu .scroll-content::-webkit-scrollbar-thumb:hover {
  background: rgb(143, 143, 143);
}
