#loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000090;
}

#loading .content {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
}
