.menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000090;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100dvh;
}

.menu .content {
  background-color: #fff;
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100dvh;
  padding: 5px;
}

.menu .menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu .scroll-content {
  overflow: auto;
}

.menu button {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 0;
  background-color: var(--c1);
  color: var(--c1c);
  border-radius: 5px;
}

.menu button.active,
.menu button:hover {
  background-color: var(--c2);
  color: var(--c2c);
}

.menu button .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.menu .course {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.menu .course button {
  text-transform: uppercase;
  font-weight: 600;
}

.menu .course-name {
  font-weight: bold;
  padding: 5px;
}

.menu hr {
  width: 100%;
  border: 1px solid var(--c1);
}
