.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000095;
  width: 100%;
  height: 100dvh;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.popup.full-back {
  background-color: var(--c3);
}

.popup .container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  border: 3px solid var(--c6);
}

.popup header {
  background-color: var(--c5);
  color: var(--c5c);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  flex-shrink: 0;
  border-bottom: 1px solid #fff;
}

.popup header .horizzontal-menu .scroll-content {
  padding-left: 0px;
}

.popup .content {
  flex-grow: 1;
  overflow: auto;
  padding: 5px;
}

.popup.popup-small {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #00000090;
}

.popup.full {
  padding: 0;
}

.popup.full .container {
  max-width: 100%;
  max-height: 100%;
}

.popup.popup-small .container {
  background-color: #fff;
  width: 100%;
  max-width: 300px;
  max-height: 400px;
  border-radius: 5px;
}

.popup textarea {
  width: 100%;
  height: 100%;
}
.popup .variants-addButton {
  padding: 10px;
}

.popup .variants-addButton {
  padding: 10px;
}

.popup .order-notes .footer {
  padding: 10px;
}

.popup footer {
  background-color: var(--c5);
  color: var(--c5c);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  flex-shrink: 0;
  border-bottom: 1px solid #fff;
}

.popup footer.buttons-group {
  display: grid;
  gap: 5px;
  flex-shrink: 0;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (min-width: 500px) {
  .popup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .popup .container {
    background-color: #fff;
    width: 100%;
    max-width: 300px;
    max-height: 500px;
  }

  .popup.md .container {
    max-width: 600px;
    max-height: 600px;
  }

  .popup.lg .container {
    max-width: 900px;
    max-height: 800px;
  }
}

.covers-table .name-open-table {
  font-size: 22px;
}
.covers-table .name-open-table .extrasmall,
.covers-table span {
  font-weight: 400;
}
.covers-table header {
  height: unset !important;
  padding: 10px 5px;
}
.covers-table .text-desc {
  text-align: center;
  font-weight: 600;
  margin-top: 0px;
}
.covers-table .text-desc-small {
  font-size: 70%;
  color: #737373;
  text-align: center;
  display: block;
  margin-top: 5px;
}
.covers-table .boxed-section {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
}

.covers-table .boxed-section .title {
  text-align: center;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

.covers-table .boxed-section .text {
  font-weight: bold;
  margin: 5px;
}

.covers-table .content {
  background-color: var(--c3);
  padding: 10px;
}
.covers-table .status-button {
  display: flex;
  gap: 5px;
}

.covers-table .total-payment {
  text-align: center;
  background-color: var(--c3);
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  line-height: 28px;
}
.covers-table .total-rest {
  color: var(--c2);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}
.covers-table .mode-payment p {
  padding-left: 5px;
}
