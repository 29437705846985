.login {
  background-color: var(--c1);
  display: flex;
  height: 100dvh;
  align-items: center;
  justify-content: center;
}

.login .content {
  background-color: #fff;
  padding: 30px;
  box-shadow: 5px 5px 5px #333;
  min-width: 400px;
  border-radius: 5px;
}

.login .content h2 {
  text-align: center;
  text-transform: uppercase;
}

.login .content .form-group {
  margin-bottom: 15px;
}

.login .content .form-group label {
  display: block;
  margin-bottom: 5px;
}

.login .content .form-group input {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.login-logo {
  text-align: center;
}
.login-logo img {
  max-width: 100%;
  padding: 20px;
  width: 250px;
}
